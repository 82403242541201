<template>
  <b-row>
    <transition name="bounce">
      <b-col md="12" v-if="can('/home')">
        <div class="card">
          <div class="card-body">
            <b-row v-if="can('count-down')">
              <CountDown />
            </b-row>
            <b-row v-if="can('graphic-home')">
              <AvisosMes />
            </b-row>
            <b-row class="mt-3" v-if="can('graphic-home')">
              <chartHome />
            </b-row>
          </div>
        </div>
      </b-col>
    </transition>
  </b-row>
</template>

<script>


import CountDown from "../../../home/CountDown.vue";
import AvisosMes from "../../../home/AvisosMes.vue";
import chartHome from "../../../home/chartHome.vue";

import can from '../../../../permission';

export default {
  name: "graphics-ot",
  components: {
    CountDown,
    AvisosMes,
    chartHome,
  },
  methods: {
    can: can
  },
};
</script>

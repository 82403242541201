<template>
  <b-col md="12">
    <div class="d-flex justify-content-between">
      <h5 align="center">OT Este mes</h5>
      <div class="form-group d-flex filtro-graph">
        <label class="mt-2 mr-2">ZONA</label>
        <select
          @change="loadAvisos()"
          v-model="cor"
          class="form-control form-control-sm mr-3"
        >
          <option value="*">Todo</option>
          <option value="ATLANTICO">Atlantico</option>
          <option value="NORTE">Norte</option>
        </select>

        <button class="btn btn-sm btn-link" @click="loadAvisos()">
          <i class="la la-sync text-primary"></i>
        </button>
      </div>
    </div>
    <div id="chart-demo">
      <DxChart
        id="chart"
        v-if="!isLoading"
        :data-source="dataSource"
        palette="Material"
        title="OT este mes"
        @point-click="onPointClick"
        @legend-click="onLegendClick"
      >
        <DxCommonSeriesSettings :type="type" argument-field="fecha" />
        <DxSeries
          v-for="(item, idx) in series"
          :key="idx"
          :value-field="item.value"
          :name="item.name"
        />
        <DxMargin :bottom="10" />
        <DxArgumentAxis :allow-decimals="false" :axis-division-factor="20">
          <DxLabel overlapping-behavior="stagger" word-wrap="none" />
        </DxArgumentAxis>
        <DxLegend vertical-alignment="top" horizontal-alignment="center" />
        <DxExport :enabled="true" />
        <DxZoomAndPan :drag-to-zoom="false" argument-axis="both" />
        <DxTooltip
          :enabled="true"
          :shared="true"
          :customize-tooltip="customizeTooltip"
          :z-index="1"
        />
      </DxChart>
    </div>
  </b-col>
</template>
<style>
/* z-index="1" */
.filtro-grap {
  width: 865px !important;
}
</style>
<script>
import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxExport,
  DxLegend,
  DxMargin,
  DxTooltip,
  DxLabel,
  DxZoomAndPan,
  // DxFormat,
} from "devextreme-vue/chart";
import axios from "axios";
import _ from "lodash";
var date = new Date();
var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  .toISOString()
  .split("T")[0];
var today = date.toISOString().split("T")[0];
// const monthNames = [
//   "Enero",
//   "Febrero",
//   "Marzo",
//   "Abril",
//   "Mayo",
//   "Junio",
//   "Julio",
//   "Agosto",x
//   "Septiembre",
//   "Octubre",
//   "Noviembre",
//   "Diciembre",
// ];

export default {
  components: {
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxExport,
    DxLegend,
    DxMargin,
    DxTooltip,
    DxLabel,
    DxZoomAndPan,
    // DxFormat,
  },
  mounted() {
    this.loadAvisos();
  },
  data() {
    return {
      isLoading: false,
      cor: "*",
      dataSource: [],
      type: "stackedspline",
      series: [
        { value: "executed", name: "Ejecutadas" },
        { value: "pending", name: "Pendientes" },
      ],
    };
  },
  methods: {
    onPointClick({ target: point }) {
      point.select();
    },

    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
    loadAvisos() {
      this.$nextTick(() => {
        this.isLoading = true;
        let config = {
          fechai: firstDay + " 00:00:00",
          fechaf: today + " 23:59:59",
          cor: this.cor,
        };
        axios.post("/home", config).then((res) => {
          res.data.general.map((el) => {
            el.pending = el.cantidad;
          });
          this.executed = res.data.ejecutadas.map((el) => {
            el.executed = el.cantidad;
          });
          this.dataSource = _.orderBy(
            _.concat(res.data.general, res.data.ejecutadas),
            ["fecha"],
            ["asc"]
          );
          this.isLoading = false;
        });
      });
    },
    customizeTooltip({ point }) {
      const data = point.data;
      let value = {};
      data.pending
        ? (value = {
            title: "pendientes",
            data: data.pending,
          })
        : (value = {
            title: "ejecutados",
            data: data.executed,
          });

      return {
        html: `<div>
                  <div class='tooltip-header'>
                    ${"OT"}
                  </div>
                  <div class='tooltip-body'>
                    <div class='series-name'>
                      <span class='top-series-name'>${value.title}</span>: 
                    </div>
                    <div class='value-text'>
                      <span class='top-series-value'>${value.data}</span>
                    </div>
                  
                    <div class='series-name'>
                      <span class='bottom-series-name'>${"Fecha"}</span>: 
                    </div>
                    <div class='value-text'>
                      <span class='bottom-series-value'>${data.fecha}</span>
                    </div>
                  </div>
                </div>`,
      };
    },
  },
};
</script>
<style>
.tooltip-header {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 5px;
  border-bottom: 1px solid #c5c5c5;
}

.tooltip-body {
  width: 200px;
}

.tooltip-body .series-name {
  font-weight: normal;
  opacity: 0.6;
  display: inline-block;
  line-height: 1.5;
  padding-right: 10px;
  width: 126px;
}

.tooltip-body .value-text {
  display: inline-block;
  line-height: 1.5;
  width: 30px;
  align-items: center;
}
</style>

<template>
  <b-card>
    <b-row>
      <b-col>
        <transition name="bounce">
          <DxTabPanel>
            <DxItem
              title="Graficas TE (Tiempo Ejecucion)"
              template="graphics-time-execute"
            />
            <template #graphics-time-execute>
              <GraphicsTE :chart="chart" :chart2="chart2" />
            </template>

            <DxItem title="Graficas OT" template="graphics-ot" />
            <template #graphics-ot>
              <GraphicsOT />
            </template>
          </DxTabPanel>
        </transition>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { DxTabPanel, DxItem } from "devextreme-vue/tab-panel";
import GraphicsTE from "./GraphicsTE.vue";
import GraphicsOT from "./GraphicsOT.vue";
// import ChartSector from "./ChartSector.vue";
// import Chart from "../../map/modal/Chart.vue";
export default {
  name: "charts-informe",
  props: {
    chart: {
      type: Array,
      default: () => [],
    },
    chart2: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    DxTabPanel,
    DxItem,
    GraphicsTE,
    GraphicsOT,
    // ChartSector,
    // Chart,
  },
};
</script>

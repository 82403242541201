<template>
  <b-card>
    <b-row class="mb-5">
      <b-col>
        <Chart
          :title="'Cantidad de trabajos por estado [filtrado]'"
          :orders="chart"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <ChartSector
          :title="'Cantidad OT por sector [filtrado]'"
          :orders="chart2"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import Chart from "../../map/modal/Chart.vue";
import ChartSector from "./ChartSector.vue";

export default {
  name: "graphics-te",
  components: {
    Chart,
    ChartSector,
  },
  props: {
    chart: {
      type: Array,
      default: () => [],
    },
    chart2: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

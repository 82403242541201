<template>
  <b-card>
    <b-row v-if="can('/reports-admin')">
      <b-col>
        <transition name="bounce">
          <DxTabPanel>
            <DxItem title="Tiempo Ejecucion" template="informe-ejecucion-tab" v-if="can('ejecution-time-report')"/>
            <template #informe-ejecucion-tab>
              <InformeEjecucionTab
                @getChart="setChart"
                @getChart2="setChart2"
              />
            </template>

            <DxItem title="Graficas" template="informe-reasignacion-tab" v-if="can('ejecution-time-graphics')"/>
            <template #informe-reasignacion-tab>
              <ChartsInforme :chart="chart" :chart2="chart2" />
            </template>


            <DxItem v-if="false" title="Busqueda Personalizada" template="custom-search-tab" />
            <template #custom-search-tab>
              <CustomSearchTab />
            </template>


          </DxTabPanel>
        </transition>
      </b-col>
    </b-row>
    <b-row v-else>
      <div class="alert alert-secondary col text-center">
        <h5 class="mb-6">No tiene permisos para esta sección</h5>
        <img
          src="assets/img/404.svg"
          alt="Not found component"
          class="mb-5"
          width="30%"
        />
      </div>
    </b-row>
  </b-card>
</template>
<script>
import { DxTabPanel, DxItem } from "devextreme-vue/tab-panel";

import InformeEjecucionTab from "./utils/InformeEjecucionTab.vue";
import ChartsInforme from "./utils/ChartsInforme.vue";
import CustomSearchTab from "./utils/CustomSearchTab.vue";
import can from "@/permission";
export default {
  name: "report-component",
  components: {
    DxTabPanel,
    DxItem,
    InformeEjecucionTab,
    ChartsInforme,
    CustomSearchTab
},
  data() {
    return {
      chart: [],
      chart2: [],
    };
  },
  mounted() {
    this.setChart();
    this.setChart2();
  },
  methods: {
    can: can,
    setChart(chart) {
      this.chart = chart;
    },
    setChart2(chart2) {
      this.chart2 = chart2;
    },
  },
};
</script>

<template>
  <div>
    <DxChart
      id="ChartSector"
      :data-source="dataChart"
      :title="title ? title : 'Cantidad de OT por sector'"
      @point-click="onPointClick"
      :class="isFirstLevel ? 'pointer-on-bars' : ''"
    >
      <!--in DxChart @point-click="onPointClick" -->
      <DxAnimation
        easing="easeOutCubic"
        :duration="500"
        :max-point-count-supported="100"
      />

      <DxCommonSeriesSettings
        argument-field="text"
        type="bar"
        hover-mode="allArgumentPoints"
        selection-mode="allArgumentPoints"
      >
        <DxLabel :visible="true">
          <DxFormat :precision="0" type="fixedPoint" />
        </DxLabel>
      </DxCommonSeriesSettings>

      <DxValueAxis>
        <DxTick :visible="!isFirstLevel" />
        <DxLabel :visible="!isFirstLevel" />
      </DxValueAxis>

      <DxSeries
        argument-field="text"
        value-field="total"
        type="bar"
        color="#565691"
        name="Total"
      >
        <DxLabel :visible="true" background-color="#071D49" />
      </DxSeries>

      <DxSeries
        argument-field="text"
        value-field="Asignado"
        type="bar"
        color="#071D49"
        name="Asignado"
      >
        <DxLabel :visible="true" background-color="#071D49" />
      </DxSeries>

      <DxSeries
        argument-field="text"
        value-field="Certificada"
        type="bar"
        color="#8A87F9"
        name="Certificada"
      >
        <DxLabel :visible="true" background-color="#071D49" />
      </DxSeries>

      <DxSeries
        argument-field="text"
        value-field="Ejecutada"
        name="Ejecutada"
        type="bar"
        color="#00B398"
      >
        <DxLabel :visible="true" background-color="#071D49" />
      </DxSeries>

      <DxSeries
        argument-field="text"
        value-field="Rechazada"
        name="Rechazada"
        type="bar"
        color="#ff6961"
      >
        <DxLabel :visible="true" background-color="#071D49" />
      </DxSeries>

      <DxSeries
        argument-field="text"
        value-field="En ejecución"
        name="En ejecución"
        type="bar"
        color="#009CDE"
      >
        <DxLabel :visible="true" background-color="#071D49" />
      </DxSeries>

      <DxSeries
        argument-field="text"
        value-field="Descargado"
        name="Descargado"
        type="bar"
        color="#3A3A60"
      >
        <DxLabel :visible="true" background-color="#071D49" />
      </DxSeries>

      <DxTooltip
        :enabled="false"
        :customize-tooltip="customizeTooltip"
        :z-index="1"
      />
      <DxLegend vertical-alignment="bottom" horizontal-alignment="center" />
      <DxExport :enabled="true" />
      <DxZoomAndPan :drag-to-zoom="false" argument-axis="both" />
    </DxChart>
    <DxButton
      :visible="!isFirstLevel"
      class="button-container"
      text="Volver"
      icon="chevronleft"
      @click="onButtonClick"
    />
  </div>
</template>
<script>
import {
  DxAnimation,
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxLabel,
  DxFormat,
  DxLegend,
  DxExport,
  DxValueAxis,
  DxTick,
  DxZoomAndPan,
  DxTooltip,
} from "devextreme-vue/chart";

import { DxButton } from "devextreme-vue/button";
export default {
  components: {
    DxAnimation,
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxLabel,
    DxFormat,
    DxLegend,
    DxExport,
    DxValueAxis,
    DxTick,
    DxZoomAndPan,
    DxTooltip,
    DxButton,
  },
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
    // title optional in the chart
    title: {
      type: String,
    },
  },
  mounted() {
    // console.log(this.orders)
  },
  watch: {
    orders() {
      this.dataChart = this.filterData('');
    },
  },
  data() {
    return {
      isFirstLevel: true,
      dataChart: this.filterData(""),
    };
  },
  methods: {
    onPointClick({ target }) {
      if (this.isFirstLevel) {
        this.isFirstLevel = false;
        this.dataChart = this.filterData(target.originalArgument);
      }
    },
    filterData(name) {
      return this.orders.filter((item) => item.parentID === name);
    },
    onButtonClick() {
      if (!this.isFirstLevel) {
        this.isFirstLevel = true;
        this.dataChart = this.filterData("");
      }
    },
    customizeTooltip(pointInfo) {
      return {
        text: `${pointInfo.originalValue}`,
      };
    },
  },
};
</script>
<style>
#ChartSector.pointer-on-bars .dxc-series rect {
  cursor: pointer;
}

.button-container {
  text-align: center;
  height: 40px;
  position: absolute;
  top: 7px;
  left: 0;
}
</style>

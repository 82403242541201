<template>
  <b-col md="12">
    <DxChart
      id="chart"
      :rotated="true"
      :data-source="items"
      :title="`Top 10 avisos por tipo - ${month}`"
    >
      <DxArgumentAxis>
        <DxLabel
          :customize-text="customizeLabelText"
          overlapping-behavior="stagger"
          word-wrap="none"
        />
      </DxArgumentAxis>

      <DxValueAxis>
        <DxTick :visible="false" />
        <DxLabel :visible="false" />
      </DxValueAxis>

      <DxSeries
        argument-field="full"
        value-field="total"
        type="bar"
        color="#00B398"
      >
        <DxLabel :visible="true" background-color="#8A87F9" />
      </DxSeries>

      <DxLegend
        :visible="false"
        vertical-alignment="top"
        horizontal-alignment="center"
      />

      <DxExport :enabled="true" />
      <DxTooltip :enabled="true" />
    </DxChart>
  </b-col>
</template>
<script>
import DxChart, {
  DxArgumentAxis,
  DxExport,
  DxSeries,
  DxValueAxis,
  DxLabel,
  DxLegend,
  DxTick,
  DxTooltip,
} from "devextreme-vue/chart";
import axios from "axios";
import _ from "lodash";
var date = new Date();
var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  .toISOString()
  .replace("T", " ")
  .slice(0, -5);
var today = date.toISOString().replace("T", " ").slice(0, -5);
const monthNames = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
export default {
  mounted() {
    this.Load();
  },
  components: {
    DxChart,
    DxArgumentAxis,
    DxExport,
    DxSeries,
    DxValueAxis,
    DxLabel,
    DxLegend,
    DxTick,
    DxTooltip,
  },
  computed: {
    cor() {
      return sessionStorage.cor;
    },
  },
  data() {
    return {
      items: [],
      month: monthNames[date.getMonth()],
    };
  },
  methods: {
    customizeLabelText({ value }) {
      return `${value}`;
    },
    Load() {
      var aviso = () => {
        this.xaxis = [];
        let config = {
          fechai: firstDay,
          fechaf: today,
          cor: this.cor,
        };
        if (this.$route.path == "/home" || this.$route.path == "/") {
          axios
            .post("/home-top-avisos", config)
            .then((res) => {
              let data = _.orderBy(res.data, ["total"], "desc");
              data.map((el) => {
                el.full = `${el.tipo_aviso}: ${el.total}`;
              });
              this.items = _.orderBy(data, ["total"], "asc");
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          clearInterval(handler);
        }
      };
      aviso();
      var handler = setInterval(aviso, 1000 * 2 * 60);
    },
  },
};
</script>

<template>
  <b-col>
    <transition name="bounce">
      <div v-if="defeated">
        <h5 class="text-center">
          Tiempo restante para próximo cargue de turno
        </h5>
        <div
          class="alert alert-secondary digital-clock text-center"
        >
          {{ timerOutput | performanceDate }}
        </div>
      </div>
    </transition>
  </b-col>
</template>
<style>
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@500&display=swap");
.digital-clock {
  font-size: 2.7em;
  font-family: "Orbitron", sans-serif;
}
</style>
<script>
import moment from "moment";
export default {
  data() {
    return {
      countDownToTime: null,
      timerOutput: null,
      defeated: false,
    };
  },
  filters: {
    performanceDate(values) {
      if (values) {
        return values
          .replace("Day", "Día")
          .replace("Hour", "Hora")
          .replace("Minutes", "Minutos")
          .replace("Second", "Segundo");
      } else {
        return "";
      }
    },
  },
  mounted() {
    const date = new Date();
    const last = new Date(date.getFullYear(), date.getMonth() + 1, -5);
    if (
      moment().format("YYYY-MM-DD HH:mm:ss") <
      moment(last).format("YYYY-MM-DD HH:mm:ss")
    ) {
      this.defeated = true;
    }
    this.countDownToTime = new Date(last).getTime();
    setInterval(() => {
      this.startTimer();
    }, 1000);
  },
  methods: {
    startTimer: function () {
      const timeNow = new Date().getTime();
      const timeDifference = this.countDownToTime - timeNow;
      const millisecondsInOneSecond = 1000;
      const millisecondsInOneMinute = millisecondsInOneSecond * 60;
      const millisecondsInOneHour = millisecondsInOneMinute * 60;
      const millisecondsInOneDay = millisecondsInOneHour * 24;
      const differenceInDays = timeDifference / millisecondsInOneDay;
      const remainderDifferenceInHours =
        (timeDifference % millisecondsInOneDay) / millisecondsInOneHour;
      const remainderDifferenceInMinutes =
        (timeDifference % millisecondsInOneHour) / millisecondsInOneMinute;
      const remainderDifferenceInSeconds =
        (timeDifference % millisecondsInOneMinute) / millisecondsInOneSecond;
      const remainingDays = Math.floor(differenceInDays);
      const remainingHours = Math.floor(remainderDifferenceInHours);
      const remainingMinutes = Math.floor(remainderDifferenceInMinutes);
      const remainingSeconds = Math.floor(remainderDifferenceInSeconds);
      this.timerOutput =
        remainingDays +
        " Days " +
        ": " +
        remainingHours +
        " Hours " +
        ": " +
        remainingMinutes +
        " Minutes " +
        ": " +
        remainingSeconds +
        " Seconds";
    },
  },
};
</script>

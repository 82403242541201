<template>
  <b-card>
    <Form @loadInformeExecute="loadInformeExecute($event)" ref="form" />
    <div v-if="data.length">
      <b-row>
        <b-col md="12">
          <div class="form-group mt-3">
            <div class="checkbox-inline">
              <label class="checkbox checkbox-primary">
                <input type="checkbox" v-model="personalized" />
                <span></span>¿Quiere personalizar su reporte?</label
              >
            </div>
          </div>
        </b-col>

        <b-col v-if="!personalized">
          <EjecutionTable :data="data" />
        </b-col>
        <b-col v-if="personalized">
          <DxPivotGrid
            id="pivotgrid"
            ref="grid"
            :data-source="data"
            :allow-sorting-by-summary="true"
            :allow-filtering="true"
            :show-borders="true"
            :show-column-grand-totals="false"
            :show-row-grand-totals="false"
            :show-row-totals="false"
            :show-column-totals="false"
            :allow-expand-all="true"
            alinment="center"
            :texts="MainText"
          >
            <DxExport :enabled="true" />
            <DxFieldChooser
              title="Genera tu reporte"
              :layout="2"
              :texts="pivotText"
              :allowSearch="true"
              :enabled="true"
              :height="400"
            />
            <DxScrolling mode="standar" />
          </DxPivotGrid>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <div v-if="!isLoading">
        <div class="text-center">
          <img src="assets/img/empty.png" alt="Empty" />
          <p class="text-muted">No hay información para mostrar</p>
        </div>
      </div>
      <div v-else class="text-center">
        <img src="assets/img/loader.gif" alt="Loading..." />
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  DxExport,
} from "devextreme-vue/data-grid";
import {
  DxPivotGrid,
  DxFieldChooser,
  DxScrolling,
} from "devextreme-vue/pivot-grid";

import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";


import axios from "axios";
import moment from "moment";
import Form from "./Form.vue";
import EjecutionTable from "./EjecutionTable.vue";

export default {
  name: "custom-search-tab",
  components: {
    DxPivotGrid,
    DxFieldChooser,
    DxScrolling,
    Form,
    EjecutionTable,
    DxExport,
},
  data() {
    return {
      data: [],
      form: {
        sectores: [],
        status: [],
        date_init: moment().format("YYYY-MM-01"),
        date_end: moment().format("YYYY-MM-DD"),
      },

      personalized: false,
      isLoading: false,
      items: [],
      pageSizes: [5, 10, 15, 30, 50],
      pivotText: {
        allFields: "Todos los campos",
        columnFields: "Columnas",
        dataFields: "Valores",
        filterFields: "Campos de filtro",
        rowFields: "Filas",
      },
      MainText: {
        noData: "No hay información",
        grandTotal: "Total",
        collapseAll: "Desplegar todo",
        expandAll: "Expandir todo",
        exportToExcel: "Exportar a Excel",
        removeAllSorting: "Quitar orden",
        showFieldChooser: "Mostrar selector de campo",
        sortColumnBySummary: "Ordenar {0} por esta columna",
        sortRowBySummary: "Ordenar {0} por esta fila",
      },
      texts: {
        editRow: "Editar",
        saveRowChanges: "Guardar",
        saveAllChanges: "Guardar cambios",
        deleteRow: "Eliminar",
        cancelRowChanges: "Cancelar",
        confirmDeleteTitle: "Información",
        confirmDeleteMessage: "¿Realmente desea eliminar el registro?",
        cancelAllChanges: "Descartar cambios",
      },
    };
  },
  mounted() {
    this.loadInformeExecute(this.$refs.form.form);
  },
  methods: {
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Information");

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            `${this.$route.name}.xlsx`
          );
        });
      });
      e.cancel = true;
    },
    async loadInformeExecute(form) {
      this.$refs.form.isLoading = true;
      let cor = sessionStorage.cor;

      try {
        const response = await axios.post("/param-ots-report", {
          cor: cor.replaceAll("'", "").split(","),
          date_end: form.date_end + " 23:59:59",
          date_init: form.date_init + " 00:00:00",
          status: form.status,
          sectores: form.sectores,
        });

        const { data } = response;
        this.data = data;
        console.log(this.data);

        this.$refs.form.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    refreshData() {
      this.loadInformeExecute(this.$refs.form.form);
    },
  },
};
</script>
